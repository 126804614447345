
<body id="home" fixAttr>
    <div class="loading-page">
        <img src="./../../assets/ReplayGlitch.webp" alt="REPLAY Pub Crawl Logo animated">
        <div class="counter">
            <hr/>
            <h2>0%
            </h2>
        </div>
    </div>
    <!-- ==================================== AVAILABILITY ==================================== -->
    <div id="availableDiv" *ngIf="!webActive">
      <img src="https://i.postimg.cc/Pt8tDtWp/bannerphoto1.webp" alt="REPLAY Pub Crawl Coming Soon Baner">
      <h2>COMING SOON</h2>
      <button type="button" class="btn btn-primary buttonContact wow fadeInUp animated" [routerLink]="['../']" data-wow-duration="900ms" data-wow-delay="300ms">AVAILABLE CITIES</button>
    </div>
    <!-- ==================================== Navigation ==================================== -->
    <nav id="navigation" class="navbar navigation-transparent fixed-top navbar-expand-lg navbar-fixed-top bg-body-tertiary" >
      <div class="container-fluid">
        <a class="navbar-brand" href="#home">
          <div id="logo">
            <picture>
                <source srcset="./../../assets/ReplayWhite.webp" type="image/webp">
                <img
                    src="./../../assets/ReplayWhite.webp"
                    alt="REPLAY Pub Crawl Logo"
                    sizes="(max-width: 600px) 100vw, 50vw">
            </picture>
          </div>
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <svg class="ham hamRotate ham8" viewBox="0 0 100 100" onclick="this.classList.toggle('active')">
            <path class="line top" d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20" />
            <path class="line middle" d="m 30,50 h 40" />
            <path class="line bottom" d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20" />
        </svg>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="nav-link" aria-current="page" href="#home" title="Home">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#tours" title="Tours">Tour Details</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#booknow" title="Book Now" (click)="openSection()">Book Now</a>
            </li>
            <li class="nav-item" *ngIf="galleryWeb && galleryWeb.length > 0">
              <a class="nav-link" href="#gallery" title="Gallery" (click)="scrollToSection('gallery')">Gallery</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#contact" title="Contact">Contact</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#faqs" title="FAQs">FAQ</a>
            </li>
            <li class="nav-item" *ngIf="stayWeb && stayWeb.length > 0 && clubWeb && clubWeb.length > 0">
              <a class="nav-link" [routerLink]="['/partners/' + webCity]" title="Partners">Partners</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <!-- ==================================== Home Slider ==================================== -->
    <section id="slider" class="home-section">
        <header class="welcome-title">
          <h1>Pub Crawl {{webCity}} - The Ultimate Nightlife Experience</h1>
        </header>
        <div id="video-background">
            <video #videoplayer autoplay loop muted playsinline class="carousel-video">
                <source src="./../../assets/welcome.webm" type="video/webm">
                Your browser does not support the video tag.
            </video>
        </div>
        <div class="video-overlay"></div>
        <div id="carousel-example-generic" class="carousel slide" data-bs-interval="6000" data-bs-touch="true" data-bs-ride="carousel" #carousel>
          <div class="carousel-inner" role="region" aria-label="Image carousel" aria-live="polite">
              <div class="carousel-item active" id="firstSlider" aria-labelledby="firstSliderTitle" aria-describedby="firstSliderDesc">
                  <div class="carousel-caption">
                      <h2 id="firstSliderTitle" data-wow-duration="500ms" data-wow-delay="300ms" class="wow fadeInUp animated">
                          <span>Pub Crawl</span>
                      </h2>
                      <h2 class="slider-title">Experience the Best Pub Crawl in {{webCity}}</h2>
                      <p id="firstSliderDesc" class="slider-description wow fadeInUp animated" data-wow-duration="700ms" data-wow-delay="300ms">
                          Reserve your spot now and unlock a world of parties waiting to be explored!
                      </p>
                      <button type="button" class="slider-button btn btn-primary buttonContact wow fadeInUp animated" (click)="openSection()" data-wow-duration="900ms" data-wow-delay="300ms">BOOK NOW</button>
                  </div>
              </div>
              <div class="carousel-item" id="secondSlider" aria-labelledby="secondSliderTitle" aria-describedby="secondSliderDesc">
                  <div class="carousel-caption">
                      <h2 id="secondSliderTitle" data-wow-duration="500ms" data-wow-delay="300ms" class="wow fadeInUp animated">
                          <span>REPLAY</span>
                      </h2>
                      <h2 class="slider-title">Join Replay for an Unforgettable {{webCity}} Nightlife</h2>
                      <p id="secondSliderDesc" class="slider-description wow fadeInUp animated" data-wow-duration="700ms" data-wow-delay="300ms">
                          Join the crew and experience an unforgettable night!
                      </p>
                      <a href="#contact"><button type="button" class="slider-button btn btn-primary buttonContact wow fadeInUp animated" data-wow-duration="900ms" data-wow-delay="300ms">CONTACT</button></a>
                  </div>
              </div>
          </div>
        </div>
    </section>
    <section class="slider-md-txt">
      <p>{{webCity}} Pub Crawl is the best way to experience the vibrant nightlife of {{webCity}}. Join our {{webCity}} Nightlife Tour to visit the top bars and clubs in the city. This pub crawl {{webCity}} offers an unforgettable night with fellow travelers and locals. Explore {{webCity}}'s best nightlife spots with Replay Pub Crawl and make the most of your night out in {{webCity}}.</p>
    </section>
    <!--==================================== WORKING DAYS ==================================== -->
    <section class="daysWrkSection working">
      <h2 class="working-title">Working Days and Hours</h2>
      <h3 class="working-days-title">Our pub crawl runs on the following days:</h3>
      <ng-container *ngIf="seasonalCity">
        <p class="working-day dayTimeItem wow fadeInUp animated" data-wow-duration="700ms">
          FROM {{ startDayDate | date: 'MMMM' }} TO {{ endDayDate | date: 'MMMM' }}
        </p>
      </ng-container>
      <ng-container *ngIf="workDaysNames.length >= 7; else showDays">
        <p class="working-day dayTimeItem wow fadeInUp animated" data-wow-duration="700ms">
          EVERY DAY
        </p>
      </ng-container>
      <ng-template #showDays>
        <p class="working-day dayTimeItem wow fadeInUp animated" data-wow-duration="700ms" *ngFor="let dayName of workDaysNames">
          {{ dayName }}
        </p>
      </ng-template>
      <h3 class="working-time-title">Starting at:</h3>
      <p class="working-time dayTimeItem wow fadeInUp animated" data-wow-duration="700ms">- {{ hours }} -</p>
    </section>
    <!--==================================== TOURS ==================================== -->
    <section id="tours" class="tours" *ngIf="tourArray">
        <div class="container">
            <div class="row">
                <div id="tourTitle" class="sec-title sec-title-margin text-center mb50 wow fadeInUp animated" data-wow-duration="1000ms">
                    <h2>Tour Details</h2>
                    <h2 class="tour-md-txt">{{webCity}} Pub Crawl Itinerary</h2>
                    <div class="devider"><i class="fa fa-bolt fa-lg"></i></div>
                </div>
                <div class="col-md-4 wow fadeInLeft" data-wow-duration="500ms">
                    <div class="service-item">
                        <div class="service-icon">
                          <i [class]="tourArray[0].tour_i1" aria-hidden="true"></i>
                        </div>
                        <div class="service-desc">
                            <h3>{{ tourArray[0].tour_h1 }}</h3>
                            <p>{{ tourArray[0].tour_p1 }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 wow fadeInUp" data-wow-duration="500ms" data-wow-delay="500ms">
                    <div class="service-item">
                        <div class="service-icon">
                          <i [class]="tourArray[0].tour_i2" aria-hidden="true"></i>
                        </div>
                        <div class="service-desc">
                            <h3>{{ tourArray[0].tour_h2 }}</h3>
                            <p>{{ tourArray[0].tour_p2 }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 wow fadeInRight" data-wow-duration="500ms" data-wow-delay="600ms">
                    <div class="service-item">
                        <div class="service-icon">
                          <i [class]="tourArray[0].tour_i3" aria-hidden="true"></i>
                        </div>
                        <div class="service-desc">
                            <h3>{{ tourArray[0].tour_h3 }}</h3>
                            <p>{{ tourArray[0].tour_p3 }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="more-md-txt">
      <p>{{webCity}} Pub Crawl details include visits to multiple venues, starting with a lively bar, followed by a popular spot, and concluding at a top nightclub with free entry. This {{webCity}} Nightlife Tour guarantees a memorable night out filled with fun activities and great company. Reserve your spot now for the best pub crawl {{webCity}} has to offer!</p>
    </section>
    <!--================================== Some facts ==================================== -->
    <section id="facts" class="factsClass" *ngIf="moreArray">
        <div class="parallax-overlay">
            <div class="container">
                <div class="row number-counters">
                    <div class="sec-title sec-title-margin text-center mb50 wow fadeInUp animated" data-wow-duration="1000ms">
                        <h2>More Info</h2>
                        <h2 class="bigger-md-txt">Detailed Information About {{webCity}} Pub Crawl</h2>
                        <div class="devider"><i class="fa fa-bolt fa-lg"></i></div>
                    </div>
                    <div class="col-md-3 col-sm-6 col-xs-12 text-center wow fadeInUp animated" data-wow-duration="500ms">
                        <div class="counters-item">
                          <i [class]="moreArray[0].more_i1" aria-hidden="true"></i>
                            <div id="inlineInfo">
                              <h3 class="countTo">{{ moreArray[0].more_h1 }}</h3><span>€</span>
                            </div>
                            <p>{{ moreArray[0].more_p1 }}</p>
                        </div>
                    </div>
                    <div id="secMoreItem2" class="col-md-3 col-sm-6 col-xs-12 text-center wow fadeInUp animated" data-wow-duration="500ms" data-wow-delay="200ms">
                        <div class="counters-item">
                          <i [class]="moreArray[0].more_i2" aria-hidden="true"></i>
                            <div id="inlineInfo">
                              <h3>{{ moreArray[0].more_h2 }}+</h3>
                            </div>
                            <p>{{ moreArray[0].more_p2 }}</p>
                        </div>
                    </div>
                    <div id="secMoreItem3" class="col-md-3 col-sm-6 col-xs-12 text-center wow fadeInUp animated" data-wow-duration="500ms" data-wow-delay="200ms">
                        <div class="counters-item">
                          <i [class]="moreArray[0].more_i3" aria-hidden="true"></i>
                            <div id="inlineInfo">
                              <h3 class="countTo">{{ moreArray[0].more_h3 }}</h3>
                            </div>
                            <p>{{ moreArray[0].more_p3 }}</p>
                        </div>
                    </div>
                    <div id="secMoreItem4" class="col-md-3 col-sm-6 col-xs-12 text-center wow fadeInUp animated" data-wow-duration="500ms" data-wow-delay="200ms">
                        <div class="counters-item">
                          <i [class]="moreArray[0].more_i4" aria-hidden="true"></i>
                            <div id="inlineInfo">
                              <h3 class="countTo">{{ moreArray[0].more_h4 }}</h3>
                            </div>
                            <p>{{ moreArray[0].more_p4 }}</p>
                        </div>
                    </div>
                </div>
                <div id="secMoreItemButton" class="col-md-3 col-sm-6 col-xs-12 text-center wow fadeInUp animated" data-wow-duration="500ms" data-wow-delay="600ms">
                  <button type="button" id="showBkngSec" class="btn btn-primary buttonContact" (click)="openSection()" *ngIf="!showSection">BOOK NOW <i class="fa-solid fa-chevron-down"></i></button>
                  <button type="button" class="btn btn-primary buttonContact" *ngIf="showSection" (click)="toggleSection()">BOOK NOW <i class="fa-solid fa-chevron-up"></i></button>
                </div>
            </div>
        </div>
    </section>
    <section class="book-md-txt">
      <p>Join our {{webCity}} Pub Crawl to experience the city’s vibrant nightlife scene. Our {{webCity}} Nightlife Tour is perfect for both tourists and locals looking for an exciting night out. Each pub crawl {{webCity}} event includes visits to the best bars and clubs, with plenty of opportunities to meet new people and enjoy the nightlife. Reserve your spot now for an unforgettable {{webCity}} nightlife experience.</p>
    </section>
    <!--================================== BOOK NOW SECTION ==================================== -->
    <mat-accordion>
      <mat-expansion-panel hideToggle [expanded]="showSection" class="bookingExpansionPanel">
        <div class="toggledSection" id="booknow" role="region" aria-labelledby="booknow-heading">
          <header class="sec-title sec-title-margin toggledSecTitle text-center mb50 wow fadeInUp animated" data-wow-duration="800ms">
            <h2>Book now</h2>
            <h2 class="bkTtl-md-txt">Book Your {{webCity}} Pub Crawl Experience Now</h2>
            <div class="devider"><i class="fa fa-bolt fa-lg"></i></div>
          </header>
          <p class="sec-description">Fill out the form below to book your spot on our {{webCity}} Pub Crawl. We look forward to partying with you!</p>
          <form>
            <div class="form-group wow fadeInUp animated" data-wow-duration="500ms">
              <label for="name">Name:</label>
              <input type="text" class="form-control" id="name" name="name" required placeholder="Name . . " [(ngModel)]="inputName" (ngModelChange)="onInputChange()">
            </div>
            <div class="form-group wow fadeInUp animated" data-wow-duration="500ms">
              <label for="phone">Phone:</label>
              <input type="tel" class="form-control" id="phone" name="phone" required placeholder="+123456789012" [(ngModel)]="inputPhone" (ngModelChange)="onInputChange()">
            </div>
            <div class="form-group wow fadeInUp animated" data-wow-duration="500ms">
              <label for="email">Email:</label>
              <input type="email" class="form-control" id="email" name="email" required placeholder="youremail@mail.com" [(ngModel)]="inputEmail" (ngModelChange)="onInputChange()">
            </div>
            <div class="form-group wow fadeInUp animated bookingDatediv" data-wow-duration="500ms">
              <label for="date">Date:</label>
              <mat-form-field>
                <input matInput id="date" [matDatepicker]="picker" [matDatepickerFilter]="filterFunction" [min]="yesterday" (focus)="picker.open()" readonly [(ngModel)]="inputDate" (ngModelChange)="onDateChange($event)">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker [startAt]="startingDate"></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="form-group wow fadeInUp animated" data-wow-duration="500ms">
              <label for="totalPeople">Total people:</label>
              <input type="number" class="form-control" id="totalPeople" name="totalPeople" required min="1" pattern="[0-9]+" inputmode="numeric" placeholder="How many people?" [(ngModel)]="inputTotalPeople" (ngModelChange)="onInputChange()">
            </div>
            <div class="form-group dividerForm wow fadeInUp animated">
              <div class="subtotalDiv">
                <p>Subtotal:</p>
                <p>{{ subtotal }} €</p>
              </div>
              <div class="feeDiv">
                <p>Fee:</p>
                <p>{{ fee }} €</p>
              </div>
              <div class="totalDiv">
                <p>Total:</p>
                <p>{{ total }} €</p>
              </div>
            </div>
            <div id="bookingWarn" class="form-group wow fadeInUp animated" *ngIf="isWarningMsg">
              <p>* Reserve now and pay at the meeting point to avoid unexpected fees. Cash at the meeting point works best for both sides.</p>
            </div>
            <div id="completedReservationDiv" class="form-group" *ngIf="isCompletedReservation">
              <p>Reservation successful! A confirmation email has been sent. (If you can't find it, please check your SPAM folder)</p>
            </div>
            <div id="completedPaymentDiv" class="form-group" *ngIf="isCompletedPayment">
              <p>Transaction successful! A confirmation email has been sent. (If you can't find it, please check your SPAM folder)</p>
            </div>
            <div id="notCompletedPaymentDiv" class="form-group" *ngIf="isErrorPayment">
              <p>Something went wrong, try again.</p>
            </div>
            <button class="form-group wow fadeInUp animated" id="reserveNowBtn" (click)="createBkngOnReserveBtn()" [ngStyle]="{ 'pointer-events': isDisabled ? 'none' : 'auto', opacity: isDisabled ? '0.5' : '1' }">Reserve Now</button>
            <p class="wow animated" id="payBtnExpaners" (click)="toggleBtnExpand()" *ngIf="!showPayBtns" [ngStyle]="{ 'pointer-events': isDisabled ? 'none' : 'auto', opacity: isDisabled ? '0.5' : '0.6' }">Pay Now <i class="fa-solid fa-chevron-down"></i></p>
            <p class="wow animated" id="payBtnExpaners" (click)="toggleBtnExpand()" *ngIf="showPayBtns">Hide <i class="fa-solid fa-chevron-up"></i></p>
            <mat-accordion>
              <mat-expansion-panel hideToggle [expanded]="showPayBtns">
                <div class="form-group wow fadeInUp animated" [ngStyle]="{ 'pointer-events': isDisabled ? 'none' : 'auto', opacity: isDisabled ? '0.5' : '1' }">
                  <div id="myPaypalButtons" #paymentRef></div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </form>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <section class="botnm-md-txt">
      <p>Booking your {{webCity}} Pub Crawl is easy and convenient. Our {{webCity}} Nightlife Tour includes a variety of options to suit your schedule. Whether you're looking for a mid-week escape, a weekend adventure, or a fresh start to your week, our pub crawl {{webCity}} events are designed to provide an amazing experience. Enjoy a lively night out any day of the week, and make lasting memories with Replay Pub Crawl. Join us for the best nightlife tour in {{webCity}} and start your week off right with an unforgettable night of fun.</p>
    </section>
    <!--================================== Gallery ==================================== -->
    <section id="gallery" class="works clearfix" #gallerySection *ngIf="galleryWeb && galleryWeb.length > 0">
        <div class="container">
            <div class="row">
                <div class="sec-title text-center mb50 wow fadeInUp animated">
                    <h2>Gallery</h2>
                    <div class="devider"><i class="fa fa-bolt fa-lg"></i></div>
                </div>
                <div class="sec-sub-title text-center wow fadeInUp animated">
                    <p>Dive into the exciting world of pub crawls through our gallery
                        showcasing the best moments of people enjoying drinks, music, and fun.</p>
                </div>
            </div>
        </div>
        <div id="galleryItems" class="wow fadeInUp animated">
          <div class="gallerySingleImg" *ngFor="let image of galleryWeb">
            <img class="galSingleImg modal-target" loading="lazy" [src]="image.img_url + '800w'" [attr.srcset]="image.img_url + '600w 600w, ' + image.img_url + '800w 800w, ' + image.img_url + '1200w 1200w'" sizes="(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 33vw" [alt]="image.img_description" (click)="openModal($event)">
          </div>
        </div>
        <div id="modal" class="modal" (click)="closeModal()" [ngStyle]="{ 'display': isModalOpen ? 'flex' : 'none' }">
          <img id="modal-content" loading="lazy" class="modal-content" [src]="modalImageUrl" alt="Preview REPLAY Gallery Item">
        </div>
    </section>
    <!--================================== Contact ==================================== -->
    <section id="contact" class="contact" *ngIf="socialArray">
        <div class="container">
            <div class="row mb50 contact-info">
                <div class="sec-title text-center mb50 wow fadeInUp animated" data-wow-duration="700ms">
                    <h2>Get In Touch</h2>
                    <div class="devider"><i class="fa fa-bolt fa-lg"></i></div>
                </div>
                <div class="sec-sub-title text-center wow fadeInUp animated" data-wow-delay="300ms" data-wow-duration="1000ms">
                    <p>Reserve your spot now! Let us know the desired date that suits you by contacting us through any of the following options. Simply click on the option you prefer:</p>
                </div>
                <div id="contactDiv" class="contact-methods">
                    <div class="contact-item contactContainer socialContact col-lg-3 col-md-3 col-sm-4 col-xs-12 wow fadeInUp animated" data-wow-duration="1000ms" id="whatsapp">
                        <img class="svgClass" id="whatsappIcon" src="./../../assets/whatsapp.svg" alt="Contact us via Whatsapp" loading="lazy" (click)="whatsappOpen()">
                        <a [href]="'https://wa.me/' + socialArray[0].whatsapp" target="_blank" rel="noopener noreferrer" aria-label="Contact us via WhatsApp"><p>WhatsApp <i class="fa fa-external-link" aria-hidden="true"></i></p></a>
                    </div>
                    <div class="contact-item contactContainer socialContact col-lg-3 col-md-3 col-sm-4 col-xs-12 wow fadeInUp animated" data-wow-delay="200ms" data-wow-duration="1000ms" id="telegram">
                        <img class="svgClass" id="telegramIcon" src="./../../assets/telegram.svg" alt="Contact us via Telegram" loading="lazy" (click)="telegramOpen()">
                        <a [href]="'https://t.me/' + socialArray[0].telegram" target="_blank" rel="noopener noreferrer" aria-label="Contact us via Telegram"><p>Telegram <i class="fa fa-external-link" aria-hidden="true"></i></p></a>
                    </div>
                    <div class="contact-item contactContainer socialContact col-lg-3 col-md-3 col-sm-4 col-xs-12 wow fadeInUp animated" data-wow-delay="400ms" data-wow-duration="1000ms" id="viber">
                        <img class="svgClass" id="viberIcon" src="./../../assets/viber.svg" alt="Contact us via Viber" loading="lazy" (click)="viberOpen()">
                        <a [href]="'viber://chat?number=' + socialArray[0].viber" target="_blank" rel="noopener noreferrer" aria-label="Contact us via Viber"><p>Viber <i class="fa fa-external-link" aria-hidden="true"></i></p></a>
                    </div>
                    <div class="contact-item contactContainer socialContact col-lg-3 col-md-3 col-sm-4 col-xs-12 wow fadeInUp animated" data-wow-delay="600ms" data-wow-duration="1000ms" id="instagram">
                        <img class="svgClass" id="instagramIcon" src="./../../assets/instagram.svg" alt="Contact us via Instagram" loading="lazy" (click)="instagramOpen()">
                        <div class="gradientLayer"></div>
                        <a [href]="'https://instagram.com/' + socialArray[0].instagram" target="_blank" rel="noopener noreferrer" aria-label="Contact us via Instagram"><p>Instagram <i class="fa fa-external-link" aria-hidden="true"></i></p></a>
                    </div>
                </div>
            </div>
        </div>
        <div id="mapDiv">
            <div class="mapcontainer">
                <div class="bg"></div>
                <div class="mapbutton">
                  <a [href]="socialArray[0].maps" target="_blank" rel="noopener noreferrer" aria-label="View on Google Maps">MAPS <i class="fa fa-external-link" aria-hidden="true"></i></a>
                </div>
            </div>
        </div>
    </section>
    <!--==================================== REVIEWS SECTION ==================================== -->
    <section id="reviews-section" class="wow fadeInUp" data-wow-duration="500ms" data-wow-delay="500ms">
      <mat-accordion class="review-accordion">
        <mat-expansion-panel (opened)="reviewOpenState = true" (closed)="reviewOpenState = false" class="review-preview">
          <mat-expansion-panel-header>
            <mat-panel-title id="review-head">
              <div class="first-row">
                <span class="hidden-span">{{ rating }} ({{ totalReviews }})</span>
                <div class="stars-container">
                  <div class="stars-border">
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                  </div>
                  <div class="stars-rating">
                    <i class="fa fa-star" *ngFor="let star of fullStars"></i>
                    <i class="fa fa-star-half" *ngIf="halfStar"></i>
                    <i class="fa fa-star empty-star" *ngFor="let star of emptyStars"></i>
                  </div> 
                </div>
                <span>{{ rating }} ({{ totalReviews }})</span>
              </div>
              <div class="second-row">
                <p>Click To Read Reviews</p>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="review-body-div">
            <div class="highlighted-div" *ngIf="reviewsArray">
              <p class="highlight-p">Highlighted</p>
              <div class="highlighted-review">
                <p class="author-p">{{ reviewsArray.name }}</p>
                <div class="review-written">
                  <div class="stars-written">
                    <i class="fa fa-star" *ngFor="let star of fullStarsHgh"></i>
                  </div>
                  <p class="review-date-p">{{ reviewsArray.date }}</p>
                </div>
                <p class="review-desc-p">{{ reviewsArray.desc }}</p>
              </div>
            </div>
            <div class="leaverew-div">
              <p class="leaverew-p">Leave review</p>
              <p class="successReview" *ngIf="successReview">Thank you for your support!</p>
              <div class="leave-review" *ngIf="!successReview">
                <div class="form-group" id="forReview-name">
                  <label for="review-name">Name:</label>
                  <input type="text" class="form-control" id="review-name" name="review-name" required placeholder="Name . . " [(ngModel)]="inputReviewName" (ngModelChange)="onChangeReview()">
                </div>
                <div class="form-group" id="forReview-desc">
                  <label for="review-desc">Review description:</label>
                  <input type="text" class="form-control forReviewInput" id="review-desc" name="review-desc" required placeholder="Write a review . . " [(ngModel)]="inputReviewDesc" (ngModelChange)="onChangeReview()">
                </div>
                <div class="stars-container give-star-cont">
                  <div class="stars-border">
                    <i class="fa fa-star"
                       *ngFor="let star of stars; let i = index"
                       (click)="rate(i + 1)"
                       [class.selected]="i < setRating"></i>
                  </div>
                  <div class="stars-rating" *ngIf="setRating > 0">
                    <i class="fa fa-star"
                       *ngFor="let star of starsArray; let i = index"
                       [class.selected]="i < setRating"></i>
                  </div>
                </div>
                <div id="secRewButton" class="col-md-3 col-sm-6 col-xs-12 text-center">
                  <button type="button" id="submitReview" class="btn btn-primary buttonContact" [ngStyle]="{ 'pointer-events': isDisabledRew ? 'none' : 'auto', opacity: isDisabledRew ? '0.5' : '1' }" (click)="onRewSubmit()">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </section>
    <!--================================== FAQs ==================================== -->
    <section id="faqs">
      <div class="sec-title text-center mb50 wow fadeInUp animated" data-wow-duration="700ms">
        <h2>FAQ</h2>
        <h2 class="feku-md-txt">{{webCity}} Pub Crawl FAQ</h2>
        <div class="devider"><i class="fa fa-bolt fa-lg"></i></div>
      </div>
      <mat-accordion class="faqPanelAll" *ngIf="faqItems && faqItems.length > 0">
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" class="faqPanelItem" *ngFor="let faq of faqItems; let i = index" [attr.id]="'faq-panel-' + i" [attr.aria-labelledby]="'faq-header-' + i">
          <mat-expansion-panel-header>
            <mat-panel-title id="faq-header-{{ i }}">
              {{ faq.faq_question }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>{{ faq.faq_answer }}</p>
        </mat-expansion-panel>
      </mat-accordion>
    </section>
    <!--================================== Footer ==================================== -->
    <footer id="footer" class="footer" *ngIf="footerHeaderArray">
        <div class="container">
            <div class="row">
                <div class="col-md-12 footerRow">

                  <div class="contactFooter text-center">
                      <p>{{ footerHeaderArray[0].footer_phone }} &nbsp;&nbsp;|&nbsp;&nbsp; </p><p><a [href]="'mailto:' + footerHeaderArray[0].footer_email">{{ footerHeaderArray[0].footer_email }}</a></p>
                  </div>

                  <p class="copyright text-center">Copyright &copy; 2023 <a href="https://www.linkedin.com/in/miomirvukovic/" target="_blank" rel="noopener noreferrer">REPLAY Pub Crawl</a>. All rights reserved.
                  </p>

                  <div class="policyFooter">
                    <p (click)="openTermsDialog()">Terms and conditions</p>
                    <p (click)="openPrivacyDialog()">Privacy Policy</p>
                    <p (click)="openRefundDialog()">Refund Policy</p>
                  </div>
                </div>
            </div>
        </div>
        <div id="fb-root"></div>
        <script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v17.0" nonce="RQWE1O9i"></script>
        <div class="fb-like facebookDiv" data-href="https://facebook.com/docs/replaypubcrawl" data-width="100px" data-layout="" data-action="" data-size="" data-share="false"></div>
    </footer>
    <a href="#home" id="back-top" aria-label="back to top"><i class="fa fa-angle-up fa-3x"></i></a>
</body>
