import { Component, ViewEncapsulation,ChangeDetectorRef } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { AuthService } from './services/auth.service';
import { BookingService } from './services/booking.service';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AdminComponent {
  title = 'ReplayPanel';

  isMobile: Observable<boolean>;
  isAdminUser: boolean = false;
  isLoggedIn: boolean = false;
  isBookingsComponentLoaded!: Observable<boolean>;
  currentRoute: string = '';
  
  isArrowVisible = new BehaviorSubject<boolean>(false);

  constructor(private breakpointObserver: BreakpointObserver, private authService: AuthService, private router: Router, private bookingService: BookingService, private changeDetectorRef: ChangeDetectorRef, private location: Location) {
    this.isMobile = this.breakpointObserver.observe([Breakpoints.Handset])
      .pipe(
        map(result => result.matches),
        shareReplay()
      );
  }
  ngOnInit(): void {
    this.startLoader()
    this.onLoginSuccess();
    // show sidemenu
    this.isBookingsComponentLoaded = this.bookingService.isBookingsComponentLoaded.asObservable();
    this.isBookingsComponentLoaded.subscribe(value => {
      this.changeDetectorRef.detectChanges();
    });
    this.getCurrentRoute();
  }
  goBack(): void {
    this.location.back();
  }
  onLoginSuccess(): void {
    this.isAdminUser = this.authService.isAdmin();
    this.isLoggedIn = this.authService.isAuthenticated();
  }
  getCurrentRoute(){
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
        this.isArrowVisible.next(this.currentRoute !== '/mikrobuva/bookings');
      }
    });
  }
  startLoader(): void {
    const loader = document.querySelector(".loading-page");
    let counter = 0;
    let c = 0;
    const interval = setInterval(() => {
      const counterElement = document.querySelector(".loading-page .counter h2");
      const hrElement = document.querySelector(".loading-page .counter hr");
      if (counterElement && hrElement) {
        counterElement.textContent = c + "%";
        hrElement.setAttribute("style", `width: ${c}%;`);
      }
      counter++;
      c++;
      if (counter === 101) {clearInterval(interval);}}, 11);
    setTimeout(() => {
      if (loader) {
        loader.setAttribute("style", "opacity: 0; transition: visibility 0.5s, opacity 0.2s linear; overflow: hidden;");
        const homeElement = document.getElementById("headContainer");
        if (homeElement) {homeElement.removeAttribute("fixAttr");}}}, 1400);
    setTimeout(() => {if (loader) {loader.setAttribute("style", "display: none;");}}, 1800);
  }
}
