import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BodyMore, BodyTour, BodySocial, WebPublicData, BodyOther, WebCity } from '../models/website.model';
import { Cacheable } from 'ngx-cacheable';

// const baseUrl = 'http://localhost:8080/api/website';
const baseUrl = 'https://replaypubcrawl.com/api/website';

@Injectable({
  providedIn: 'root'
})
export class WebsiteService {

  constructor(private http: HttpClient) { }

  updateJsonData(city: string): Observable<any> {
    return this.http.post(`${baseUrl}/updateCityData`, { city })
      .pipe(
        tap(response => console.log('Update response:', response)),
        catchError(this.handleError<any>('updateJsonData'))
      );
  }

  // SLANJE CONFIRMATION EMAIL-A NAKON USPESNOG PLACANJA KORISNIKU
  sendEmailConfirmation(data: any): Observable<any> {
    return this.http.post(`${baseUrl}/sendEmailConfirmation`, data);
  }
  // SLANJE EMAIL-A NAKON USPESNOG PLACANJA ADMINU
  sendEmail(data: any, clientEmail: string): Observable<any> {
    return this.http.post(`${baseUrl}/sendEmail`, { ...data, clientEmail });
  }
  // Slanje konfimacije nakon uspesnog REZERVISANJA KORISNIKU
  sendMailClient(data: any): Observable<any> {
    return this.http.post(`${baseUrl}/sendMailClient`, data);
  }
  // Slanje konfimacije nakon uspesnog REZERVISANJA ADMINU
  sendMailAdmin(data: any, clientEmail: string): Observable<any> {
    return this.http.post(`${baseUrl}/sendMailAdmin`, { ...data, clientEmail });
  }

  // Kreiranje grada (kopira prvi grad u tabeli web_body samo menja ime u ime novog grada)
  create(data: any): Observable<any> {
    return this.http.post(baseUrl, data);
  }
  // Dohvatanje svih gradova iz tabele CITIES
  @Cacheable({
    maxAge: 30 * 24 * 60 * 60 * 1000, // 30 dana u milisekundama
    slidingExpiration: true
  })
  getCitiesOnly(){
    return this.http.get<WebCity>(`${baseUrl}/allcities`).pipe(
      catchError(this.handleError<WebCity>('getCitiesOnly', {}))
    );
  }
  // Update dostupnosti grada AVAILABLE/UNAVAILABLE
  @Cacheable({
    maxAge: 30 * 24 * 60 * 60 * 1000, // 30 dana u milisekundama
    slidingExpiration: true
  })
  updateCityAvailability(cityName: string, availability: boolean): Observable<any> {
    return this.http.post(`${baseUrl}/updateCityAvailability`, { cityName: cityName, availability: availability });
  }
  // Dohvatanje svih dana za odabrani grad
  getCityDays(web_city_name: string): Observable<any> {
    const url = `${baseUrl}/workDays/${web_city_name}`;
    return this.http.get<any>(url).pipe(
      catchError(this.handleError<any>('getCityDays', {} as any))
    );
  }
  // Update radnih/neradnih dana za odabrani grad
  updateDays(updatedDays: any[]): Observable<any> {
    return this.http.post(`${baseUrl}/updateDays`, updatedDays);
  }
  // Dohvati / Blokiraj / Odblokiraj - datum za odabrani grad
  getBlockedDates(city: string): Observable<any[]> {
    return this.http.get<any[]>(`${baseUrl}/getBlockedDates`, { params: { web_city: city } });
  }
  blockDate(date: any, city: string): Observable<any> {
    return this.http.post(`${baseUrl}/blockDates`, { date, web_city: city });
  }
  unblockDate(date: Date, city: string): Observable<any> {
    return this.http.delete(`${baseUrl}/unblockDate`, { body: { date, web_city: city } });
  }
  // Dohvati sve SEZONSKE podatke (datum, season - true/false)
  getSeasonData(city: string): Observable<any> {
    return this.http.get<any[]>(`${baseUrl}/getSeasonData`, { params: { web_city: city } });
  }
  // Update true/false za SEASON opciju za odredjen grad
  updateCitySeason(cityName: string, season: boolean): Observable<any> {
    return this.http.post(`${baseUrl}/updateCitySeason`, { cityName: cityName, season: season });
  }
  // Update DATUM za SEASON opciju za odredjen grad
  updateCitySeasonDates(cityName: string, start: any, end: any): Observable<any> {
    return this.http.post(`${baseUrl}/updateCitySeasonDates`, { cityName: cityName, start: start, end: end });
  }
  // Brise SVE redove iz tabela gde je city name koji je poslat u zahtevu
  deleteCity(web_city_name: any): Observable<any> {
    return this.http.delete(`${baseUrl}/delete/${web_city_name}`);
  }
  // Dohvata SVE podatke websajta na osnovu zahtevanog grada
  @Cacheable({
    maxAge: 30 * 24 * 60 * 60 * 1000, // 30 dana u milisekundama
    slidingExpiration: true
  })
  getAllPublicDataByCity(web_city_name: string): Observable<WebPublicData> {
    const url = `${baseUrl}/alldatabycity/${web_city_name}`;
    return this.http.get<WebPublicData>(url).pipe(
      catchError(this.handleError<WebPublicData>('getAllPublicDataByCity', {} as WebPublicData))
    );
  }
  // Dodavanje nove slike u GALLERY tabelu
  addPhotoGallery(data: any): Observable<any> {
    return this.http.post((`${baseUrl}/addNewPhoto`), data);
  }
  // Dodavanje novog kluba/bara u CLUBS tabelu
  addNewClub(data: any): Observable<any> {
    return this.http.post((`${baseUrl}/addNewClub`), data);
  }
  // Dodavanje novog smestaja u STAYS tabelu
  addNewStay(data: any): Observable<any> {
    return this.http.post((`${baseUrl}/addNewStay`), data);
  }
  // UPDATE TOUR polja u tabeli web_body za prosledjeni grad
  updateTourDataByCity(webCity: string, tourData: BodyTour[]): Observable<any> {
    const url = `${baseUrl}/updateTour`;
    return this.http.post(url, { webCity: webCity, tourArray: tourData }).pipe(
        tap(_ => console.log('Tour data updated successfully')),
        catchError(this.handleError<any>('updateTourDataByCity'))
    );
  }
  // UPDATE MORE polja u tabeli web_body za prosledjeni grad
  updateMoreDataByCity(webCity: string, moreData: BodyMore[]): Observable<any> {
    const url = `${baseUrl}/updateMore`;
    return this.http.post(url, { webCity: webCity, moreArray: moreData }).pipe(
        tap(_ => console.log('More data updated successfully')),
        catchError(this.handleError<any>('updateMoreDataByCity'))
    );
  }
  // UPDATE SOCIAL polja u tabeli web_body za prosledjeni grad
  updateSocialDataByCity(webCity: string, socialData: BodySocial[]): Observable<any> {
    const url = `${baseUrl}/updateSocial`;
    return this.http.post(url, { webCity: webCity, socialArray: socialData }).pipe(
        tap(_ => console.log('Social data updated successfully')),
        catchError(this.handleError<any>('updateSocialDataByCity'))
    );
  }
  // UPDATE FOOTER & HEADER polja u tabeli web_body za prosledjeni grad
  updateFooterDataByCity(webCity: string, footerData: BodyOther[]): Observable<any> {
    const url = `${baseUrl}/updateFooter`;
    return this.http.post(url, { webCity: webCity, footerHeaderArray: footerData }).pipe(
        tap(_ => console.log('Footer & header data updated successfully')),
        catchError(this.handleError<any>('updateFooterDataByCity'))
    );
  }
  // UPDATE FOOTER & HEADER polja u tabeli web_body za prosledjeni grad
  updateSeoKeysByCity(webCity: string, seoKeywords: string): Observable<any> {
    const url = `${baseUrl}/updateSeo`;
    return this.http.post(url, { webCity: webCity, seoKeywords: seoKeywords }).pipe(
        tap(_ => console.log('Seo keywords data updated successfully'))
    );
  }
  // UPDATE FAQs - RADI UPDATE NA OSNOVU SVIH ID-eva PA NE POVLACI INFORMACIJU GRADA
  updateFaq(faqItems: any[]): Observable<any> {
    return this.http.post<any>(`${baseUrl}/updateFAQItems`, faqItems);
  }
  // Obrisi SLIKU iz GALLERY tabele na osnovu ID-a
  deleteGalleryItem(id: any): Observable<any> {
    return this.http.delete(`${baseUrl}/delete/gallery/${id.toString()}`).pipe(
        catchError(this.handleError<any>('deleteGalleryItem'))
    );
  }
  // Obrisi CLUB iz CLUBS & BARS tabele na osnovu ID-a
  deleteClubsItem(id: any): Observable<any> {
    return this.http.delete(`${baseUrl}/delete/clubs/${id.toString()}`).pipe(
        catchError(this.handleError<any>('deleteClubsItem'))
    );
  }
  // Obrisi HOTEL iz STAYS tabele na osnovu ID-a
  deleteStaysItem(id: any): Observable<any> {
    return this.http.delete(`${baseUrl}/delete/stays/${id.toString()}`).pipe(
        catchError(this.handleError<any>('deleteStaysItem'))
    );
  }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log the error
      return throwError(error); // re-throw the error
    };
  }
  // ================= PRAVLJENJE NIZOVA ZA PRIKAZ ====================
  // ======= (DA BIH OMOGUCIO UPDATE-OVANJE ZASEBNIH SEKCIJA) =========
  getPriceFromArray(data: any) {
    const webBody = data.webBody[0];
    const moreArray = [{more_h1: webBody.more_h1}];
    return moreArray;
  }
  extractTourArray(data: any): BodyTour[] {
    const webBody = data.webBody[0];
    const tourArray: BodyTour[] = [{ tour_h1: webBody.tour_h1, tour_i1: webBody.tour_i1, tour_p1: webBody.tour_p1, tour_h2: webBody.tour_h2, tour_i2: webBody.tour_i2, tour_p2: webBody.tour_p2, tour_h3: webBody.tour_h3, tour_i3: webBody.tour_i3, tour_p3: webBody.tour_p3 }];
    return tourArray;
  }
  extractMoreArray(data: any): BodyMore[] {
    const webBody = data.webBody[0];
    const moreArray: BodyMore[] = [{ more_h1: webBody.more_h1, more_i1: webBody.more_i1, more_p1: webBody.more_p1, more_h2: webBody.more_h2, more_i2: webBody.more_i2, more_p2: webBody.more_p2, more_h3: webBody.more_h3, more_i3: webBody.more_i3, more_p3: webBody.more_p3, more_h4: webBody.more_h4, more_i4: webBody.more_i4, more_p4: webBody.more_p4 }];
    return moreArray;
  }
  extractSocialArray(data: any): BodySocial[] {
    const webBody = data.webBody[0];
    const socialArray: BodySocial[] = [{ instagram: webBody.instagram, whatsapp: webBody.whatsapp, telegram: webBody.telegram, viber: webBody.viber, maps: webBody.maps }];
    return socialArray;
  }
  extractFooterHeaderArray(data: any): BodyOther[] {
    const webBody = data.webBody[0];
    const footerHeaderArray: BodyOther[] = [{ footer_email: webBody.footer_email, footer_phone: webBody.footer_phone, microdata_url: webBody.microdata_url }];
    return footerHeaderArray;
  }
  extractSeoData(data: any) {
    const webBody = data.webBody[0];
    const seoKeywords =   webBody.seo_keywords;
    return seoKeywords;
  }
  // =================== REVIEWS ====================
  // Dodavanje nove recenzije u REVIEWS tabelu
  addNewReview(data: any): Observable<any> {
    return this.http.post((`${baseUrl}/addNewReview`), data);
  }
  getAllReviews(city: string): Observable<any> {
    return this.http.post(`${baseUrl}/getAllReviews`, { city });
  }
  getHighlightedReview(city: string): Observable<any> {
    return this.http.post(`${baseUrl}/getHighlightedReview`, { city });
  }
  // Obrisi REVIEW iz REVIEW tabele na osnovu ID-a
  deleteReview(id: any): Observable<any> {
    return this.http.delete(`${baseUrl}/delete/reviews/${id.toString()}`).pipe(
        catchError(this.handleError<any>('deleteReview'))
    );
  }
  // UPDATE REVIEW u REVIEW tabele na osnovu ID-a
  updateReview(review: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${baseUrl}/update/reviews/${review.id}`, review, { headers });
  }

  validateRewName() {
    const nameInput = document.getElementById('review-name') as HTMLInputElement;
    if (nameInput.value.length < 4) {
      nameInput.classList.add('invalid');
      return false
    } else {
      nameInput.classList.remove('invalid');
      return true
    }
  }
  validateRewDesc() {
    const nameInput = document.getElementById('review-desc') as HTMLInputElement;
    if (nameInput.value.length < 50) {
      nameInput.classList.add('invalid');
      return false
    } else {
      nameInput.classList.remove('invalid');
      return true
    }
  }
  validateRewRating() {
    const ratingInput = document.getElementById('review-rating') as HTMLInputElement;
    const ratingValue = parseInt(ratingInput.value, 10);
    if (isNaN(ratingValue) || ratingValue < 1 || ratingValue > 5) {
      ratingInput.classList.add('invalid');
      return false;
    } else {
      ratingInput.classList.remove('invalid');
      return true;
    }
  }
}
