import { ActivatedRouteSnapshot } from '@angular/router';

interface MetaInfo {
  [key: string]: {
    title: string;
    description: string;
    keywords: string;
  };
}

const DEFAULT_META_INFO = {
  title: "REPLAY Pub Crawls - Global Nightlife Tours & Pub Crawls",
  description: "Discover global nightlife with REPLAY Pub Crawls. Explore top bar & pub crawls in cities worldwide for unforgettable nights out and vibrant experiences!"
};

export const META_INFO: MetaInfo = {
  "/": {
    title: "REPLAY Pub Crawls - Global Nightlife Tours & Pub Crawls",
    description: "Discover global nightlife with REPLAY Pub Crawls. Explore top bar & pub crawls in cities worldwide for unforgettable nights out and vibrant experiences!",
    keywords: "pub crawl, nightlife tours, Replay Pub Crawl, best pub crawls, city pub crawl, bar crawl, bar pub crawl, pub crawl bar, bar hopping, santacon bar crawl, halloween bar crawl, st patricks day bar crawl, pub crawl near me, bar crawl near me, halloween pub crawl, sin city pub crawl, bar crawl nation, santa crawl, nola bar crawl, bar hopping near me, londonpartypubcrawl, bar crawl st patricks day, charlotte bar crawls, st paddy's day pub crawl, st pattys bar crawl, st pattys day bar crawl, santa bar crawl, bar crawl christmas, tower pub crawl, st pats pub, otley run"
  },
  "/mikrobuva": {
    title: "Admin Panel",
    description: "",
    keywords: ""
  }
};

export function getMetaInfo(route: ActivatedRouteSnapshot): { title: string; description: string } {
  const webCity = route.params['webCity'];
  const data = webCity || route.routeConfig?.path || '';
  if (META_INFO[data]) {
    const { title, description } = META_INFO[data];
    return { title: title.replace('{{webCity}}', webCity), description: description.replace('{{webCity}}', webCity) };
  }
  return DEFAULT_META_INFO;
}
