  <div class="banerPhoto">
    <div class="overlayChooseCity"></div>
    <div class="centeredContainer">
      <div class="centeredContent">
        <div class="cityItem" *ngFor="let city of dbCities" [routerLink]="['/' + city.name]" >
          <div class="cityLogo">
            <img [src]="'./../../../../assets/cities/' + city.url +'.svg'" [alt]="'Pub Crawl for ' + city.name">
          </div>
          <p class="cityName">{{ city.name }}</p>
        </div>
      </div>
    </div>
  </div>
  <div id="startHomeInfoDiv">
    <div id="home-text">
      <h1>Experience the Ultimate Nightlife with Replay Pub Crawls - Bar Crawls and Pub Crawls!</h1>
        <p>Experience the ultimate nightlife adventure in style as you explore the vibrant nightlife across various cities around the globe. Select your city and join the best pub crawl and bar crawl tours for an unforgettable experience!</p>
        <p><strong>Highlights:</strong></p>
        <ul>
          <li>Explore the hottest nightlife spots in each city.</li>
          <li>Discover local drinks and unique experiences at every stop.</li>
          <li>Book now for a guaranteed exciting night out in multiple cities.</li>
        </ul>
      <h2>Explore Our Global Nightlife Tours</h2>
        <p>We offer nightlife tours in some of the most exciting cities worldwide. From energetic party scenes to vibrant local cultures, our tours guarantee a fantastic night out wherever you choose to go.</p>
        <p>Ready to dive into unforgettable nightlife adventures? Book your REPLAY Pub Crawl tour today and experience the best of global nightlife!</p>
      <h2>Why Choose REPLAY Pub Crawl?</h2>
        <p>At REPLAY Pub Crawl, we provide an unmatched nightlife experience across numerous cities.</p>
        <p>Our tours are meticulously designed to ensure a fantastic time in every city, with expert guides, exclusive access to top venues, and a lively atmosphere.</p>
        <p>Whether you're a solo traveler, couple, or group, our nightlife tours promise vibrant nights and unforgettable memories. Bring your positive energy and enjoy the freedom to decide how much time to spend in each venue.</p>
        <p>We aim to offer the best value for your money, ensuring a memorable experience at a great price.</p>
    </div>
    <div id="advertisement">
      <h2>Join Us for an Unforgettable Nightlife Adventure!</h2>
        <p>Don't miss out on incredible nights out. Our tours are crafted to provide amazing experiences in various vibrant locations. Get ready to meet fellow travelers, enjoy great drinks, and dance the night away!</p>
        <ul>
          <li>Glow paint provided for an extra fun experience</li>
          <li>Perfect for solo travelers, couples, and small groups</li>
          <li>Small group sizes ensure personalized attention from your guide</li>
        </ul>
    </div>
  </div>

